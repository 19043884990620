import { React, useState, useEffect } from "react";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useNavigate } from "react-router-dom";
import { RiFileSearchFill } from "react-icons/ri";
import Login from "../Login/Login";
import DocumentGrid from "./DocumentGrid";
const Dashboard = ({ token, setToken }) => {
  let items_default = [
    {
      id: 7,
      created: "2023-02-24T03:20:43.555253Z",
      title: "chatGPT",
      resources: [],
    },
    {
      id: 8,
      created: "2023-02-24T03:20:57.387502Z",
      title: "Project with Microsoft",
      resources: [],
    },
  ];
  const [items, setItems] = useState(items_default);
  const [selectedItem, setSelectedItem] = useState("");
  // const [selected, setSelected] = useState(false)
  const [promptNew, setPromptNew] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);
  const navigate = useNavigate();

  const postProjects = async (title) => {
    const response = await fetch(
      "https://api.tryquotable.com/research/project/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          title: title,
        }),
      }
    );
    //error handling
    if (response.status === 401) {
      console.log("401");
      setToken(null);
      navigate("/login");
      return false;
    }
    const data = await response.json();
    console.log(data);
    return true;
  };

  useEffect(() => {
    const getProjects = async () => {
      console.log("get projects");
      const response = await fetch(
        "https://api.tryquotable.com/research/project/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 401) {
        console.log("401");
        setToken(null);
        navigate("/login");
        return false;
      }
      const data = await response.json();

      console.log(data);
      setItems(data);
      return true;
    };
    console.log("use effect");
    getProjects();
    return () => {
      setRefreshProjects(false);
    };
  }, [token, navigate, setToken,refreshProjects]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
    if (true) {
      console.log("prompt new");
      setPromptNew(true);
      setSelectedItem(string);
    } else {
      setPromptNew(false);
    }
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected

    // setSelected(true)
    console.log("selected");
    console.log(item);
    //navigate("/project", { state: { title: item } });
    navigate("/project", { state: { item: item, token: token } });
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  // const handleKeyPress = (target, value) => {
  //     console.log("keypressed")
  //     navigate('/project', { state: { title: selectedItem } });
  // }
  const handleCreateProject = async (e) => {
    console.log("create project");
    // const status = await 
    postProjects(selectedItem);
    // if (status) navigate("/project", { state: { title: selectedItem } });
    setRefreshProjects(true)
  };

  return (
    <div className="quotable-subtle-bg h-screen ">
      {token ? (
        <div className=" quotable-subtle-bg  border-neutral-100 ">
          <div className="quotable-subtle-bg">
            <div className="flex pt-2 px-6">
              <p className="px-1">Home </p>
              <p >> </p>
              <p className="px-1">All projects </p>



            </div>

          </div>
          <div className="flex h-full py-2 px-5 quotable-subtle-bg">
            <span className="h-full leading-normal pr-4 pt-2"><RiFileSearchFill size={60} className={"quotable-blue-fg"} /></span>
            <div className="pt-1">
              <p className="mr-16 text-black text-3xl ">Search</p>
              <p className="mr-16 text-slate-600 text-xl ">Projects</p>
            </div>

          </div>

          <div className="flex flex-col gap-y-5 px-5 quotable-subtle-bg ">
            <h1 className=" place-self-center text-3xl font-bold text-gray-900 sm:text-3xl py-5 mb-2 justify-center">
              Enter your project name
            </h1>
            <div className="flex place-self-center  justify-center w-2/3">
            <div className="w-1/2 mr-10">
              <ReactSearchAutocomplete
                items={items}
                fuseOptions={{ keys: ["title"] }}
                resultStringKeyName="title"
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                // formatResult = {formatResult}
                showNoResults={false}
              />
            </div>
            {promptNew ? (
              <p
                onClick={handleCreateProject}
                state={{ title: selectedItem }}
                className="p-1 px-6 pt-2 text-center text-white bg-blue-500 rounded-full baseline"
              >
                {" "}
                Create Project{" "}
              </p>
            ) : (
              <p className="p-1 px-6 pt-2 text-center text-white bg-gray-500 rounded-full baseline">
                {" "}
                Create Project{" "}
              </p>
            )}
            </div>
            <DocumentGrid items ={items} token={token} setRefresh ={setRefreshProjects}/>
          </div>
        </div>

      ) : (
        <Login token={token} setToken={setToken} />
      )}
    </div>
  );
};

export default Dashboard;
