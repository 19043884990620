import { React, useState, useEffect } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import AudioPlayBackSimple from "../AudioPlayBack/AudioPlayBackSimple";
import ProjectResourceContentSearch from "./ProjectResourceContentSearch";
import toast, { Toaster } from 'react-hot-toast';
import DeleteResource from "./DeleteResource";

const ProjectResourceContent = ({ selectedItem, token, setRefreshResources }) => {
    const [includedFiles, setIncludedFiles] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [file, setFile] = useState("");
    const [transcript, setTranscript] = useState([]);
    const [summary, setSummary] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const getSummary = async (summaryUrl) => {
        const response = await fetch(summaryUrl);
        setSummary(await response.json());
    }
    // const getTranscript = async (transcriptUrl) => {
    //     const response = await fetch(transcriptUrl);
    //     setTranscript(await response.json());
    // }

    useEffect(() => {
        const getResources = async () => {
            console.log("get projects");
            const response = await fetch(
                `https://api.tryquotable.com/research/resource/${selectedItem.id}/file/`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response);
            if (response.status === 401) {
                console.log("401");
                //   setToken(null);
                //   navigate("/login");
                return false;
            }
            const data = await response.json();

            console.log("data[0]: ", data[0]?.file);
            console.log("data[1]: ", data[1]?.file);
            console.log("data[2]: ", data[2]?.file);
            if (data.length === 0) {
                setIncludedFiles([]);
                return false;
            }
            setIncludedFiles(data);

            return true;
        };
        console.log("use effect");
        if (selectedItem) {
            getResources();
        }
        // getSummary(includedFiles[2]?.file);
        return () => {
            setRefresh(false);
        };
        // eslint-disable-next-line
    }, [selectedItem, refresh, token]);

    useEffect(() => {
        if (includedFiles.length >= 3) {
            setTranscript([])
            setSummary([])
            getSummary(includedFiles[2].file);
        }
    }, [includedFiles]);

    const getFileUploadPromise = () => {
        const formData = new FormData();
        formData.append("file", file.selectedFile);

        return fetch(
            `https://api.tryquotable.com/research/resource/${selectedItem.id}/file/`,
            {
                method: "POST",
                headers: {

                    "Authorization": `Bearer ${token}`,
                },
                body: formData,
            }
        );
    }

    const onFileChange = (e) => {
        // Update the state
        setFile({ selectedFile: e.target.files[0] });
    };

    const handleFileUpload = (e) => {
        toast.promise(
            getFileUploadPromise(),
            {
                loading: 'Loading',
                success: (data) => 'Successfully uploaded your file',
                error: (err) => `This just happened: ${err.toString()}`,
            },
            {
                style: {
                    minWidth: '250px',
                },
            }
        );
    }
    // const handleDelete = () => {
    //     deleteResource(selectedItem);
    //     setConfirmDelete(false);
    // };





    // const deleteButton = (
    //     <button onClick={() => setConfirmDelete(true)}>Delete Resource</button>
    // );



    return (
        <>
            {selectedItem ? (
                <div className="border-2 border-neutral-100 quotable-subtle-bg flex-auto ">
                    <Toaster />
                    <div className="relative p-3 pl-5 border-b-2">
                        <div className="flex justify-between">
                            <h2 className="text-2xl font-medium mb-5 mt-3">{selectedItem?.title}</h2>
                            <div className=" ">
                                <DeleteResource token={token} selectedItem={selectedItem} setRefresh={setRefresh} setRefreshResources={setRefreshResources}
                                    confirmDelete={confirmDelete} setConfirmDelete={setConfirmDelete} />

                                <p
                                    onClick={() => setConfirmDelete(true)}
                                    className="inline-block rounded border border-red-600 px-6 py-3 text-sm font-medium text-red-600 hover:bg-red-600 hover:text-white focus:outline-none focus:ring active:bg-red-500"
                                >

                                    Delete Resource
                                </p>
                            </div>
                        </div>
                        {includedFiles.length >= 3 &&
                            <>
                                <AudioPlayBackSimple
                                    audioUrl={includedFiles[0].file}
                                    transcriptFile={includedFiles[1].file}
                                    transcript={transcript}
                                    setTranscript={setTranscript}
                                    summary={summary}


                                />

                            </>
                        }

                    </div>
                    {includedFiles.length < 1 &&
                        <div className="flex justify-center">
                            <input
                                onChange={onFileChange}
                                type="file"
                                name="file"
                                id="file"
                                className="inputfile"
                            />
                            <button
                                onClick={handleFileUpload}
                                className="border-2 border-black bg-slate-50"
                            >
                                {" "}
                                Submit
                            </button>
                        </div>
                    }
                    {includedFiles.length >= 3 &&
                        < ProjectResourceContentSearch token={token} selectedItem={selectedItem}
                            summary={summary}
                            transcript={transcript}
                        />
                    }
                </div>
            ) : (
                <div className="grid h-screen justify-items-center place-items-center border-2 border-neutral-100 quotable-subtle-bg flex-auto">
                    <div className="h-1/2 w-1/2 opacity-50 justify-center justify-items-center align-middle">
                        <AiOutlineFileSearch className="h-1/2 w-1/2 mx-auto " />
                        <p className="text-3xl mx-auto align-middle justify-center text-center">
                            Select a resource
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProjectResourceContent;
