import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LockClosedIcon } from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom';
async function signupUser(credentials) {
  let response =  ""
  try
  {
    response = await fetch('https://api.tryquotable.com/users/register/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
"email": credentials.email,
"password1":   credentials.password,
"password2":   credentials.password
})
  })
}
catch (error) {
  return undefined
}
  
  // console.log(response)
  return response.json()
      // .then(data => data.json())
  }
// const response = {
//   "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc0NzQ0OTc4LCJpYXQiOjE2NzQ3NDQ2NzgsImp0aSI6IjRkZGVjZDkzMjg5MDQzYmZhOTUzNzEzNzBlNjgxOTAzIiwidXNlcl9pZCI6MjZ9.tNPtJoC8HHB9eMk4pyubJ7R4WTSqTqbEgzUAuyzbSZs",
//   "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY3NDgzMTA3OCwiaWF0IjoxNjc0NzQ0Njc4LCJqdGkiOiI1NzRjMjEzZmZiZTk0YzU3YmJhYmVmYTkyMjBhNDQyYSIsInVzZXJfaWQiOjI2fQ.0oUU2byYpXM6iR2JBdo3Yd0CmA6NCS_92TWTu2jVt9g",
//   "user": {
//       "pk": 26,
//       "username": "yihechen",
//       "email": "yihechen@seas.upenn.edu",
//       "first_name": "",
//       "last_name": ""
//   }
// }
// return response
//    }

export default function Signup( {setToken} ) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  // const [goodResponse, setGoodResponse] = useState(false);
  const [status, setStatus] = useState("");
  const handleSubmit = async e => {
    e.preventDefault();
    const response = await signupUser({
      email,
      password
    });
    // console.log(response.ok);
    if(response?.detail) {
      console.log(response);
      setStatus(response?.detail);

    }
    else{
      console.log("error");
      setStatus(response?.email[0]);
    }
    // setStatus(response?.detail);
    // setToken(token);
  
  }

  return(
    <>

    


    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Create your Quotable account            </h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
              </a>
            </p> */}
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email" className="sr-only">
                  email
                </label>
                <input
                  id="eamil"
                  name="eamil"
                  type="text"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Mikehunt@quotable.com"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div> */}
        <div className="text-sm">
              <div className="text-sm">
               
                  <NavLink to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">Have an account? Log in </NavLink>
                  
               
              </div>
            </div> 
            <div>
              <button 
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Create
              </button>
            </div>
           
           {status &&<div className="text-sm">
                <label className="font-medium text-indigo-600 hover:text-indigo-500">
                  {status}
                </label>
              </div>}
            
           
          </form>
        </div>
      </div>
    </>
  )
}

Signup.propTypes = {
  setToken: PropTypes.func.isRequired
};