import { React, useEffect, useState, useRef } from "react";
import { BiChevronRight } from "react-icons/bi";
const srtTimeToSeconds = (timeString) => {
  const regex = /^(\d{2}):(\d{2}):(\d{2}),(\d{3})$/;
  const match = timeString.match(regex);
  if (!match) {
    throw new Error("Invalid SRT time string format");
  }
  const hours = parseInt(match[1]);
  const minutes = parseInt(match[2]);
  const seconds = parseInt(match[3]);
  const milliseconds = parseInt(match[4]);
  return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
}

const parseTimestamps = (lines, output1, output2) => {
  // let temp = []
  for (let i = 0; i < lines.length; i++) {
    if (
      /^\d{2}:\d{2}:\d{2},\d{3} --> \d{2}:\d{2}:\d{2},\d{3}$/.test(lines[i])
    ) {
      const [startTimeStr, endTimeStr] = lines[i].split(" --> ");
      const startTime = srtTimeToSeconds(startTimeStr)
      const endTime = srtTimeToSeconds(endTimeStr)
      const index = lines[i - 1].match(/(\d+)/)[0];
      const speaker = lines[i + 1].match(/^\[(.+)\]:/)[1];
      const content = lines[i + 1].match(/^\[.+]: (.+)$/)[1];

      output1.push({ index, startTime, endTime, speaker, content });
    }
  }

  let speaker = [];
  let lastspeaker = "";
  for (let i = 0; i < output1.length; i++) {
    if (!lastspeaker) {
      speaker.push(output1[i]);
      lastspeaker = output1[i].speaker;
      continue;
    }
    if (output1[i].speaker === lastspeaker) {
      speaker.push(output1[i]);
    } else {
      output2.push(speaker);
      speaker = [];
      speaker.push(output1[i]);
      lastspeaker = output1[i].speaker;
    }
  }
};

const AudioPlayBackSimple = ({ audioUrl, transcriptFile, transcript, setTranscript, summary }) => {
  const audioPlayerRef = useRef(null);
  const [playbackTime, setPlaybackTime] = useState(0.0);
  const activeWordRef = useRef(null);

  const onWordClick = (word) => {
    audioPlayerRef.current.currentTime = word.startTime
    activeWordRef.current = document.getElementById(`word-${word.index}`);
  }

  const updateTime = () => {
    setPlaybackTime(audioPlayerRef.current.currentTime)
  }

  useEffect(() => {
    audioPlayerRef.current.ontimeupdate = updateTime
  }, [])

  


  useEffect(() => {
    getTranscript(transcriptFile);
    // eslint-disable-next-line
  }, [transcriptFile]);

  useEffect(() => {
    if (activeWordRef.current) {
      activeWordRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
    // eslint-disable-next-line
  }, [activeWordRef.current]);


  const getTranscript = async (transcriptUrl) => {
    const response = await fetch(transcriptUrl);
    const data = await response.text();
    const lines = String(data).split("\n");

    const indexTimestamp = [];
    const contentTimestamp = [];

    parseTimestamps(lines, indexTimestamp, contentTimestamp);

    setTranscript(contentTimestamp);
    console.log("indexTimestamp");
    console.log(contentTimestamp);
  };

  return (
    <div className="">
      <div className="w-full mx-auto">
        <audio className="w-full" controls src={audioUrl} ref={audioPlayerRef} />
      </div>

      <div className="flex w-full mx-auto font-bold mt-4 mb-5 ml-2">
        <div className="w-1/5">   </div>
        <div className="w-2/5"> Transcript </div>
        <div className="w-2/5"> Clean Transcript </div>
      </div>

      <div className="h-96 overflow-y-auto space-y-5">
        {transcript.map((segment, i) => (
          <div key={`segment-${i}`}>
            <div className="flex w-full mx-auto space-x-2 " key={`segment-${i}`}>
              <div className="w-1/5 ">
                <div className={transcript[i][0].startTime < playbackTime && playbackTime < transcript[i][transcript[i].length - 1].endTime ?
                  " flex  justify-between font-bold rounded-md py-4 quotable-blue-bg drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.1)]" : " flex  justify-between font-bold rounded-md py-4 bg-white drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.1)]"}>
                  <span className={transcript[i][0].startTime < playbackTime && playbackTime < transcript[i][transcript[i].length - 1].endTime ?
                    "text-white font-sans font-light px-5 " : "text-black font-sans font-bold px-5 "}> {transcript[i][0].speaker.concat(":")}</span>
                  <span className="pr-5 pt-1"><BiChevronRight size={20} className={transcript[i][0].startTime < playbackTime && playbackTime < transcript[i][transcript[i].length - 1].endTime ?
                    "text-white" : "text-black "} /></span>
                </div>

              </div>
              <div className="w-2/5 flex flex-wrap rounded-md bg-white drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.1)] px-3 py-3">
                {segment.map((word, j) => (
                  <div
                    onClick={(e) => onWordClick(word)}
                    className={word.startTime < playbackTime && playbackTime < word.endTime ?
                      "px-0.5 text-blue-800 bg-slate-300" : "px-0.5"}
                    key={word.index}
                    id={`word-${word.index}`}
                    ref={word.startTime < playbackTime && playbackTime < word.endTime ? activeWordRef : null}
                  >
                    {word?.content}
                  </div>
                ))}
              </div>
              <div className="w-2/5 ounded-md bg-white drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.1)] px-3 py-3" key={`summary-${i}`}>
                {summary[i] && summary[i][1]}
              </div>
            </div>
          </div>

        ))}
      </div>
    </div>
  )

}

export default AudioPlayBackSimple;


        // <div className="h-80 my-auto overflow-y-auto flex flex-wrap mb-3 border-2 border-cyan-500">

	// 	 <div className="w-2/5 flex-shrink-0">
	// 	 </div>
	//        </>
        //      ))}
        //  </div>
