import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {AiOutlineCloudUpload} from 'react-icons/ai';

const Upload = ({ setButtonClick, buttonClick, message, setMessage, postResource,refresh,setrefresh }) => {

    const cancelButtonRef = useRef(null);

    const createResource = () => {
        //const status = await postResource(selectedItem);
        if (message === '') {
            alert("Please enter a message");
            return;
        }
        setButtonClick(false);
        postResource(message);
        setrefresh(!refresh)
    };

    const handleChange = (event) => {
        setMessage(event.target.value);

    };

    return (

        <Transition.Root show={buttonClick} as={Fragment}>
            <Dialog
                as='div'
                className='relative z-10'
                initialFocus={cancelButtonRef}
                onClose={setButtonClick}
            >
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 z-10 overflow-y-auto'>
                    <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                    <div className='sm:flex sm:items-start'>
                                        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
                                            <Dialog.Title
                                                as='h1'
                                                className='text-2xl font-medium leading-6 text-gray-900'
                                            >
                                                Resource Creation
                                            </Dialog.Title>
                                            <div className='mt-10 w-full'>
                                                {/* <section className="rounded-3xl shadow-2xl"> */}
                                                <div className="text-left w-full">
                                                    <p className="text-sm font-semibold tracking-widest text-slate-500">
                                                        Enter Title of Resource:
                                                    </p>

                                                    <div className='pt-5'>
                                                        <label className="sr-only">
                                                            Title
                                                        </label>
                                                        <input
                                                            className="w-1/2 rounded-lg border-gray-200 text-sm"
                                                            placeholder="Title"
                                                            type="text"
                                                            id="title"
                                                            onChange={handleChange}
                                                            value={message}
                                                        />
                                                    </div>

                                                </div>
                                                {/* </section> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                                    
                                    <button
                                        type='button'
                                        className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                        onClick={() => {
                                            setButtonClick(false);
                                        }}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="group relative flex w-1/2 justify-around rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={createResource}
                                        ref={null}
                                    >
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                            <AiOutlineCloudUpload className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                                        </span>
                                        <span>Create Resource</span>
                                    </button>


                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Upload;
