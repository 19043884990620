import React from 'react'
import { NavLink } from 'react-router-dom'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
const NavbarLanding = ({navigation,token,location,removeToken}) => {
  
  return (
    <>
    <nav className="relative container mx-auto p-6">
      <div className="flex items-center justify-between">
        <NavLink to="/" className="font-serif text-2xl font-bold text-blue-500 hover:drop-shadow-md">
          Quotable
        </NavLink>

        <div className="flex space-x-4">
          {navigation.map((item) => (
            ((item.auth && token) || (!item.auth)) &&
            <NavLink
              key={item.name}
              to={item.href}
              className={classNames(
                location===item.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium'
              )}
              // aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </NavLink>
          ))}
        </div>

        {!token && (
          <NavLink
            to="/login"
            className="p-2 px-6 pt-2 text-white bg-blue-500 rounded-full baseline"
          > Login </NavLink>)}
        {token && (
          <p onClick={removeToken}
            className="p-2 px-6 pt-2 text-white bg-blue-500 baseline"
          > Logout </p>)}


      </div>
    </nav>
    </>
  )
}

export default NavbarLanding