import { React } from "react";
import ResourceInnerLabel from "./ResourceInnerLabel";
import { GrFormAdd } from "react-icons/gr";

const ProjectSidebar = ({
  title,
  items,
  handleItemClick,
  selectedItem,
  token,
  project,
  resourceCreation,
  setResourceCreation,
  message,
  setMessage
}) => {



  const handleCreateResource = async (e) => {
    console.log("create research");

    setResourceCreation(true);
    //if (status) navigate("/project", { state: { title: selectedItem } });
  };



  return (
    <div className=" h-screen border-1 border-slate-300">
      <div className="h-full w-full px-2 py-4 overflow-y-auto text-3xl font-bold text-gray-900 quotable-subtle-bg dark:bg-gray-800 ">


        <div className=" ">
          <div
            onClick={handleCreateResource}
            className="inline-block rounded border border-indigo-600 px-3 py-3 ml-5 mb-5 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
          >
            <GrFormAdd className="inline-block text-indigo-600 hover:text-white " size = {20} />
            <span className="whitespace-nowrap">Create Resource</span>
          </div>

          <nav
            aria-label="Teams Nav"
            className="flex flex-col text-md font-bold text-gray-900 "
          >
            {items.map((item) => (
              <ResourceInnerLabel
                key={item.id}
                onClick={(e) => handleItemClick(e, item)}
                text={item.title}
                selected={item.id === selectedItem?.id}
              />
            ))}
          </nav>


        </div>
        {/* /////////////// */}
      </div>
    </div>
  );
};

export default ProjectSidebar;
