
import { useState, useRef, useEffect } from 'react'
import { AiOutlineSearch } from "react-icons/ai";
import toast from 'react-hot-toast';
const ProjectResourceContentSearch = ({ selectedItem, token, transcript, summary }) => {

    const [searchContent, setSearchContent] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [sentences, setSentences] = useState([]);
    const sentencesRef = useRef([]);
    const embeddingIndicesRef = useRef([]);

    const returnSentence = (index) => {
        // console.log(sentences)
        // console.log(sentencesRef.current)
        // console.log("index: ", index, "sentence: ", sentences[index])
        return sentences[index]
    }
    const createSentences = (embedding_indices) => {
        let sentences = []
        let currentSentence = ""
        for (let i = 0; i < embedding_indices.length; i++) {
            currentSentence = currentSentence + summary[embedding_indices[i]][1]
            let splitSentences = currentSentence.split(/[.?!]+/)
            for (let k = 0; k < splitSentences.length; k++) {
                if (splitSentences[k] !== " " && splitSentences[k] !== "  " && splitSentences[k] !== "" && splitSentences[k] !== "\n") {
                    sentences.push({
                        sentence: splitSentences[k] + `.`, speaker: summary[embedding_indices[i]][0],
                        turn: embedding_indices[i], sentence_index: k
                    })
                }
            }
            currentSentence = ""
        }
        console.log("sentences: ", sentences)
        return sentences
    }


    useEffect(() => {
        setSearchResult([])
    }, [selectedItem])
    const expandList = (inputStr) => {
        let result = []
        let items = inputStr.split(",")
        for (let i = 0; i < items.length; i++) {
            if (items[i].includes("-")) {
                let range = items[i].split("-")
                for (let j = parseInt(range[0]); j <= parseInt(range[1]); j++) {
                    result.push(j)
                }
            } else {
                result.push(parseInt(items[i]))
            }
        }
        return result
    }


    const formatSearchResult = (searchResult) => {


        let first_pass = String(searchResult).replaceAll("][", ",").split("]")



        let result = []
        for (let i = 0; i < first_pass.length - 1; i++) {
            if (first_pass[i] === "") {
                continue
            }
            console.log(first_pass[i])
            let text = first_pass[i].split("[")[0].replace(/[.?!]+/, '')
            let index = expandList(first_pass[i].split("[")[1])
            if(text === "" || text === " "){
                continue
            }
            let object = {
                text: text,
                index: index
            }
            console.log(object)
            result.push(object)
        }

        return result
    }


    const evidenceReturn = (index) => {
        let currentSpeaker = "";
        let prevTurn = "";
        let prevSentenceIndex = -1;
        return index?.map((i) => {
            const { speaker, sentence, turn, sentence_index } = returnSentence(i);
            let content = null;
            if (currentSpeaker !== speaker) {
                currentSpeaker = speaker;
                content = (
                    <div className='pb-2'>  
                    <div>
                    <p className="px-1 ">{speaker.replace("[","").replace("]","").concat(":")}</p>
                    </div>
                        
                        <p className="px-1">{sentence}</p>
                    </div>
                );
            } else {
                if (turn === prevTurn && sentence_index === prevSentenceIndex + 1) {
                    content = (
                        <>
                            <p className="px-1">{sentence}</p>
                        </>
                    );
                } else {
                    content = (
                        <>
                            <p className="px-1">...</p>
                            <p className="px-1">{sentence}</p>
                        </>
                    );
                }
            }
            prevTurn = turn;
            prevSentenceIndex = sentence_index;
            return (
                <div key={i}>
                    {content}
                </div>
            );
        });
    };
    const searchOnChange = (e) => {
        console.log(e.target.value);
        setSearchContent(e.target.value);
    }
    const getSearchResults = async () => {
        console.log("get projects");
        const toastid = toast.loading('Waiting...', { timeout: 15000 });
        const response = await fetch(
            `https://api.tryquotable.com/research/resource/${selectedItem.id}/query/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    "question": searchContent,
                })
            }
        );
        console.log(response);
        const data = await response.json();
        if (response.ok) {
            toast.success("Success!", { duration: 1000, id: toastid });
        }
        else {
            toast.error(`Error! ${JSON.stringify(data)}`, { duration: 1000, id: toastid });
        }
        if (response.status === 401) {
            console.log("401");
            //   setToken(null);
            //   navigate("/login");
            return false;
        }


        embeddingIndicesRef.current = data.embedding_indices;
        sentencesRef.current = createSentences(data.embedding_indices)
        setSentences(sentencesRef.current)

        const answer = formatSearchResult(data.answer)
        setSearchResult(answer)
        return true;
    }

    const onSearch = async (e) => {
        e.preventDefault();
        console.log("search");
        console.log(searchContent);
        await getSearchResults();
    }
    return (
        <>
            <div className="flex pt-2 my-5 text-gray-600 justify-center">

                <input className="w-1/2 border-2 border-gray-300 bg-white h-10 px-5 pr-6 rounded-lg text-sm focus:outline-none"
                    type="search" name="search1" placeholder="Search" onChange={searchOnChange}></input>
                <div className='pl-5 rounded-md'>
                    <div onClick={onSearch} className='rounded-md bg-slate-200 px-5 py-1 text-gray-600 hover:quotable-blue-bg hover:text-white'>
                        <button type="submit" className="  " >
                    
                        <AiOutlineSearch className="  h-6 w-6 fill-current" />
                    
                </button>
                </div>
                </div>

            </div>
            <div className="">

                <div>
                    {searchResult.length > 0 && searchResult?.map((item) => (
                        <div className='flex mb-4' key={item.text}>
                            <div className='w-1/2  rounded-md bg-white py-4 px-4 drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.1)] '>
                                <p className="">{item.text}</p>
                            </div>
                            
                            <div className='w-1/2 quotable-subtle-bg rounded-md bg-white py-4 px-4 drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.1)]'>
                                {evidenceReturn(item.index)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

export default ProjectResourceContentSearch