import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Login from "../Login/Login";
import ProjectResourceContent from "./ProjectResourceContent";
import ProjectSidebar from "./ProjectSidebar";
import CreateResource from "./CreateResource";
import { AiFillProfile } from "react-icons/ai";
export default function Project({ token, setToken }) {
  const location = useLocation();
  const title = location.state['item']?.title;
  console.log(location);

  const id = location.state["item"].id;
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [resourceCreation, setResourceCreation] = useState(false);
  const [message, setMessage] = useState("");
  const [refreshResources, setRefreshResources] = useState(false);

  const handleItemClick = (e, item) => {
    e.preventDefault();
    setSelectedItem(item);
  };
  console.log(title);

  const postResource = async (title) => {
    console.log(title);
    // convert project.id from in to string
    console.log(toString(location.state["item"].id));
    console.log(token);
    const response = await fetch(
      "https://api.tryquotable.com/research/resource/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          title: title,
          project_ids: [location.state["item"].id],
        }),
      }
    );
    //error handling
    if (response.status === 401) {
      console.log("401");
      //setToken(null);
      //navigate("/login");
      return false;
    }
    const data = await response.json();
    console.log(data);
    setMessage("")
    return true;
  };




  useEffect(() => {
    console.log("use effect");
    const getResources = async () => {
      console.log("get projects");
      const response = await fetch(
        `https://api.tryquotable.com/research/project/${id}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${location.state["token"]}`,
          },
        }
      );
      console.log(response);
      if (response.status === 401) {
        console.log("401");
        //   setToken(null);
        //   navigate("/login");
        return false;
      }
      const data = await response.json();

      console.log(data.resources);
      setItems(data.resources);
      return true;
    };
    setSelectedItem(null);
    getResources();
    return () => {
      setRefreshResources(false);
    }
  }, [location, token, id, refreshResources]);
  return (
    <div>
      <div className="quotable-subtle-bg">
        <div className="flex pt-2 px-6">
        <p className="px-1">Home </p>
        <p >> </p>
        <p className="px-1">All projects </p>
        <p >> </p>
        <p className="px-1">{title} </p>
           

        </div>
        
      </div>
      <div className="flex h-full py-2 px-5 quotable-subtle-bg">
        <span className="h-full leading-normal pr-4 pt-2"><AiFillProfile size={60} className={"quotable-blue-fg"}/></span>
        <div className="pt-1">
          <p className="mr-16 text-black text-3xl ">{title}</p>
          <p className="mr-16 text-slate-600 text-xl ">Resources</p>
        </div>
        
      </div>

      <div className="flex flex-col items-center">
        {resourceCreation && <CreateResource buttonClick={resourceCreation} setButtonClick={setResourceCreation}
          message={message} setMessage={setMessage} postResource={postResource} setrefresh={setRefreshResources} refresh={refreshResources} />}
        {token ? (
          <div
            className="flex mx-auto w-full"
            style={{ gridTemplateColumns: "1fr 2fr", zIndex: 0 }}
          >

            <ProjectSidebar
              className='z-0'
              title={title}
              items={items}
              selectedItem={selectedItem}
              project={location.state["item"]}
              handleItemClick={handleItemClick}
              token={location.state["token"]}
              resourceCreation={resourceCreation}
              setResourceCreation={setResourceCreation}
              message={message}
              setMessage={setMessage}
            />

            <ProjectResourceContent className='z-0' selectedItem={selectedItem} token={token} setRefreshResources={setRefreshResources} />
          </div>
        ) : (
          <Login token={token} setToken={setToken} />
        )}


      </div>
    </div>
  );
}
