import React, { useState } from 'react';
import Research from "./components/Research/Research";
import Login from './components/Login/Login';
import Account from './components/Account/Account';

import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

import Navbar from './components/Navbar';
import About from './components/About';
import Home from './components/Home';
import Signup from './components/Signup/Signup';
import NotFound from './components/NotFound/NotFound';
import Dashboard from './components/Dashboard/Dashboard';
import Project from './components/Project/Project';

//function setToken(userToken) {
  //sessionStorage.setItem('token', JSON.stringify(userToken));
//}

//function getToken() {
  //const tokenString = sessionStorage.getItem('token');
  //const userToken = JSON.parse(tokenString);
  //return userToken?.token
//}

function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.access_token
    // return userToken
  };
  const [token, setToken] = useState(getToken());
  const saveToken = userToken => {
    // console.log("saving")
    localStorage.setItem('token', JSON.stringify(userToken));
    // console.log(userToken?.access_token)
    setToken(userToken?.access_token);
  };
  const deleteToken = () => {
    localStorage.removeItem('token');
    setToken(null);
  }

  return {
    setToken: saveToken,
    removeToken: deleteToken,
    token
  }

}

function App() {
  const { token, setToken,removeToken } = useToken();

  const navigate = useNavigate();
  
  return (
    <>
      {/* <BrowserRouter> */}
        <Navbar   token={token} removeToken = {removeToken}/>
       
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />}/>
          <Route path="/login" element={<Login setToken={setToken} navigate = {navigate}/>} />
          <Route path="/signup" element={<Signup setToken={setToken}/>} />
          <Route path="/research" element={<Research token = {token} setToken={setToken}/>} />
          <Route path="/account" element={<Account token = {token} setToken={setToken}/>} />
          <Route path="/dashboard" element={<Dashboard token = {token} setToken={setToken}/>} />
          <Route path="/project" element={<Project token = {token} setToken={setToken}/>} />
          <Route path="/404" element={<NotFound/>} />
        </Routes>
        
      {/* </BrowserRouter> */}
    </>
);
}

export default App;
