
export default function Home() {
  return(
    <div className="container flex flex-col-reverse md:flex-row items-center mx-auto mt-10 space-y-0 ">
      <div className="flex flex-col mb-32 space-y-12 md:w-1/2 mx-2 my-5">
        <h1 className="max-w-md text-3xl font-bold text-center md:text-4xl md:text-left">
          An Internal Search Engine for Customer Research
        </h1>

        <p className="max-w-sm text-center text-blue700 md:text-left">
          A product research search tool for product managers that allow PMs to <span className="italic font-bold">instantly</span> find and 
          embed customer insights from research into shareable product proposals
        </p>
      </div>

      <div className="md:w-1/2 mx-2">
        <img src="/hero.svg" alt="" />
      </div>
    </div>
  )
}
