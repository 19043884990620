import { useState } from "react";
import DeleteProject from "./DeleteProject";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const DocumentGrid = ({ items, token, setRefresh }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

    const navigate = useNavigate();
  items.sort((a, b) => {
    if (a.created < b.created) {
      return 1;
    }
    if (a.created > b.created) {
      return -1;
    }
    return 0;
  });

  const handleClick = (item) => {
    console.log("Clicked item:", item);
    navigate("/project", { state: { item: item, token: token } });
  };

  return (
    <div className="grid grid-cols-3 gap-4 p-10 ">
      {items.map((item) => (
       
        <div key={item.id}  className="bg-white shadow-md rounded-md p-5">
            <div className="flex justify-between">
          <h2 onClick = {()=>handleClick(item)} className="text-lg font-bold mb-3">{item.title}</h2>
         
            <button
               className="pb-2 text-gray-500"
               onClick={(e) => {
                 setSelectedItem(item);
                 setConfirmDelete(true);
               }}
             >
                <AiOutlineDelete size={20} />
             </button>
             </div>
          <p className="text-gray-500 text-sm mb-2">
            Created: {new Date(item.created).toLocaleDateString()}
          </p>
          <p className="text-gray-500 text-sm">
            Resources: {item.resources.length}
          </p>
          {/* <div className="flex flex-wrap mt-2 text-gray-400">
              {item.resources.map((resource) => (
                <span key={resource.id} className="mr-2 mb-2">
                
                  
                  {resource.title}
                </span>
              ))}
            </div> */}
        </div>
      ))}
      <DeleteProject
        token={token}
        selectedItem={selectedItem}
        setConfirmDelete={setConfirmDelete}
        setRefresh={setRefresh}
        setRefreshResources={setRefresh}
        confirmDelete={confirmDelete}
      />
    </div>
  );
};

export default DocumentGrid;
