import {
  useLocation
} from "react-router-dom";
import { useEffect,useState } from "react";
import NavbarLanding from "./NavbarLanding";
import NavbarResearch from "./NavbarResearch";

const navigation = [
  { name: 'About', href: '/about', current: false,auth:false },
  // { name: 'Research', href: '/research', current: false, auth:true },
  { name: 'Dashboard', href: '/dashboard', current: false, auth:true },
]







export default function Navbar({ token, removeToken }) {

  const [location, setLocation] = useState("")
  const useLocationbody = useLocation()
  useEffect(() => {
    
    setLocation(useLocationbody.pathname)
  },[useLocationbody])
  return (
    <>
    {location==="/"? 
     <NavbarLanding navigation={navigation} token = {token} location={location} removeToken= {removeToken}/>
    :
     <NavbarResearch navigation={navigation} token = {token} location={location} removeToken = {removeToken}/>
    }
    </>
  )
}
        //<div class="hidden md:flex space-x-6">
          //<Link to="/about" class="hover:text-red-400">About</Link>
        //</div>
