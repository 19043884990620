import React from 'react'
import { BsFileText } from 'react-icons/bs'

const ResourceInnerLabel = ({text, onClick, selected}) => {
    return (
        <>
        {selected ?
        <span onClick={onClick} className="flex items-center gap-2 pl-4 py-2 pr-10 bg-gray-300 text-black "
        >
            <BsFileText />

            <span class="text-sm font-medium whitespace-nowrap"> {text} </span>
        </span>
        :
        <span onClick={onClick} className="flex items-center gap-2 pl-4 py-2 pr-10 text-gray-500 hover:bg-gray-200 hover:text-gray-700"
        >
            <BsFileText />

            <span className="text-sm font-medium whitespace-nowrap"> {text} </span>
        </span>
        }
     
        </>
    )
}

export default ResourceInnerLabel